@import '@core/scss/core.scss';
@import './assets/scss/styles';


body{
    background: #f8f7fa !important;
}


.overly_popup{
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(0,0,0,.2);
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
}


// custom body scrollbar
body,
.scrollbar_{
  scroll-behavior: smooth;
}

body::-webkit-scrollbar,
.scrollbar_::-webkit-scrollbar {
  width: 4px;
  border-radius: 4px;

}

/* Track */
body::-webkit-scrollbar-track,
.scrollbar_::-webkit-scrollbar-track {
  background: rgba(233, 233, 233, 0.4);
  border-radius: 4px;
}
body::-webkit-scrollbar-track,
.scrollbar_:hover::-webkit-scrollbar-track {
  background: rgba(233, 233, 233, 1);
}

/* Handle */
body::-webkit-scrollbar-thumb,
.scrollbar_::-webkit-scrollbar-thumb {
  background: rgba(200, 200, 200, 0.4);
  border-radius: 4px;
}
body::-webkit-scrollbar-thumb,
.scrollbar_:hover::-webkit-scrollbar-thumb {
  background: rgba(200, 200, 200, 1);
}

/*
  * Handle on hover
*/
body::-webkit-scrollbar-thumb:hover,
.scrollbar_::-webkit-scrollbar-thumb:hover {
  background: #555;
}



$height : 28px;
$width :56px;
$space: 3px;
.checkbox{
    appearance: none;
    width: $width ;
    height: $height;
    // border:1px solid #aaa;
    border-radius:$height;
    display: flex;
    align-items: center;
    position: relative;
    background: #fff;
    border: 1px solid #7367f0;
    cursor: pointer;
  
    &::before{
      content: '';
      width: calc($height - $space * 2);
      left : $space;
      position: absolute;
      aspect-ratio: 1;
      border-radius: 50%;
      background: #7367f0;
      transition: all 0.4s ease-in-out;
    }
    &:checked{
      background: #7367f0;
      // box-shadow: 4px 4px 16px 0px rgb(16, 163, 41);
      box-shadow: rgb(67, 24, 255,0.4) 0px 3px 12px -2px, rgb(0, 0, 0,0.3) 0px 3px 7px -3px;
      &::before{
        transform: translateX(calc($width - $height - 1px));
        background: #fff;
      }
    }
  
  }




  .loading {
    position: absolute;
    left: calc(50% - 35px);
    top: 50%;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 3px solid transparent;
  }
  
  .loading .effect-1,
  .loading .effect-2 {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-left: 3px solid rgba(121, 97, 249, 1);
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .loading .effect-1 {
    animation: rotate 1s ease infinite;
  }
  
  .loading .effect-2 {
    animation: rotateOpacity 1s ease infinite 0.1s;
  }
  
  .loading .effect-3 {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-left: 3px solid rgba(121, 97, 249, 1);
    -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
    animation: rotateOpacity 1s ease infinite 0.2s;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .loading .effects {
    transition: all 0.3s ease;
  }
  
  @keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
    }
  }
  
  @keyframes rotateOpacity {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      opacity: 0.1;
    }
  
    100% {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
      opacity: 1;
    }
  }